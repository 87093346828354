<template lang="pug">
div.container
  div(v-if="!event.name")
    loading-spinner(message="Loading event, visitors and members. Yeah, it takes a while...")
  div(v-if="event.name")
    b-modal(ref="visitorUpdateModal" title="Now that you're here..." header-bg-variant="info" header-text-variant="light")
      b-container(fluid)
        b-form
          b-form-row.mb-1
            h6 It's been a while. Are these still up-to-date?
              br/
              small.text-muted We ask every 6 months
          b-form-row
            b-form-group.col-sm(label="First Name" label-for="firstName")
              b-input(type="text" name="firstName" v-model="lastVisitor.firstName")
            b-form-group.col-sm(label="Last Name" label-for="lastName")
              b-input(type="text" name="lastName" v-model="lastVisitor.lastName")
          b-form-row.mb-4
            b-form-group.col-sm(label="Email" label-for="email")
              b-input(type="email" name="email" v-model="lastVisitor.email")
            b-form-group.col-sm(label="Organization" label-for="organization")
              b-input(type="text" name="organization" v-model="lastVisitor.organization")
      div(slot="modal-footer")
        b-button.m-1(size="sm" variant="secondary" @click="$refs.visitorUpdateModal.hide()") Not now
        b-button.m-1(size="sm" variant="primary" @click="quickUpdateVisitor(lastVisitor)") Looks good!

    div.row.justify-content-center
      b-alert(v-if="oldEvent" variant="warning" show) <strong>Warning!</strong> This is a past event. Checking people in will mess historical data.
    div.row.justify-content-center.text-center
      h1 {{event.name}} ({{event.date | moment("DD.MM.YYYY")}}) in {{event.location}}
    div.row.justify-content-center.text-center
      p {{totalCheckedInVisitors}} checked in so far
    br/
    br/
    div.row.justify-content-center.text-center.user-search-box.mb-4
      b-form(inline)
        b-input(type="text" placeholder="Search" v-model="searchText" @input="resultLimit = 20")
        | &nbsp;&nbsp;OR&nbsp;&nbsp;
        b-button(variant="info" size="lg" @click="showQuickAddForm = !showQuickAddForm") Add New
        | &nbsp;&nbsp;OR&nbsp;&nbsp;
        b-button(variant="primary" size="lg" :to="'kiosk/' + event._id") Open Kiosk Mode

    div.jumbotron(v-show="showQuickAddForm")
      h2 Quick Create & Check-in
      b-form
        b-form-row
          b-form-group.col-sm(label="First Name *" label-for="firstName")
            b-input(type="text" name="firstName" v-model="newVisitor.firstName" required)
          b-form-group.col-sm(label="Last Name *" label-for="lastName")
            b-input(type="text" name="lastName" v-model="newVisitor.lastName" required)
        b-form-row
          b-form-group.col-sm(label="Email *" label-for="email")
            b-input(type="email" name="email" v-model="newVisitor.email" required)
          b-form-group.col-sm(label="Organization" label-for="organization")
            b-input(type="text" name="organization" v-model="newVisitor.organization")
        b-form-row.justify-content-center
          b-button.mr-3(variant="success" @click="quickAddVisitor(newVisitor)" :disabled="!isFormValid") Add Visitor
          b-button(variant="warning" @click="resetQuickAddForm()") Cancel

    div.row.d-flex.justify-content-center
      div.col-lg-5.col-md-6.col-xl-4
        b-list-group
          b-list-group-item(active) Visitors
          b-list-group-item.table-row-link.d-flex.justify-content-between.align-items-center(v-for="visitor in displayedVisitors" :key="visitor._id" @click="toggleCheckIn(visitor)" button :variant="visitor.checkedIn ? 'secondary' : 'default'")
            span(v-if="visitor.organization") {{visitor.firstName}} {{visitor.lastName}} <small class="text-muted">| {{visitor.organization}}</small>
            span(v-else) {{visitor.firstName}} {{visitor.lastName}}
            b-badge(v-show="visitor.isLoading" variant="secondary")
              fa-icon(icon="spinner" size="1x" spin)
              |  Updating...
            b-badge(v-show="visitor.checkedIn && !visitor.isLoading" variant="success") Checked-in
        p.text-center {{visitorSearchResults.length}} search results
          |
          |
          span(v-show="displayedVisitors.length < visitorSearchResults.length") ({{displayedVisitors.length}} shown)

      div.col-lg-5.col-md-6.col-xl-4
        b-list-group
          b-list-group-item(active) Active Members
          b-list-group-item.d-flex.justify-content-between.align-items-center(v-for="member in displayedMembers" :key="member._id")
            span {{member.firstName}} {{member.lastName}} <small class="text-muted">| {{member.igdaFinlandId}}</small>
            b-badge(variant="warning" v-if="member.membershipType == 'Lifetime'") <fa-icon icon="star"></fa-icon> Lifetime
            b-badge(variant="success" v-else-if="member.validUntil > expiryThreshold") <fa-icon icon="check"></fa-icon> {{member.membershipType}}
            b-badge(variant="success" v-else-if="member.validUntil > now") <fa-icon icon="clock"></fa-icon> {{member.membershipType}}
            b-badge(variant="secondary" v-else) Expired
        p.text-center {{memberSearchResults.length}} search results
          |
          |
          span(v-show="displayedMembers.length < memberSearchResults.length") ({{displayedMembers.length}} shown)

    div.row.justify-content-center
      b-button(v-show="displayedVisitors.length < visitorSearchResults.length || displayedMembers.length < memberSearchResults.length" size="sm" variant="info" @click="loadMore()") Load more
</template>

<script>
export default {
  data: function () {
    return {
      event: {
        name: null
      },
      visitors: [],
      members: [],
      groups: [],
      searchText: '',
      showQuickAddForm: false,
      oldEvent: false,
      totalCheckedInVisitors: 0,
      newVisitor: {},
      resultLimit: 20,
      now: new Date(),
      expiryThreshold: new Date(),
      lastVisitor: {}
    }
  },
  computed: {
    // attendance, event age
    isFormValid: function () {
      let result = true
      if (this.newVisitor.firstName === '' || this.newVisitor.lastName === '' || this.newVisitor.email === '') result = false
      return result
    },
    visitorSearchResults: function () {
      return this.visitors.filter(visitor => {
        let s = this.searchText.toLowerCase()
        let hit = false

        // These could be cached but does it matter?
        let lastAndFirst = visitor.lastName + ' ' + visitor.firstName
        let firstAndLast = visitor.firstName + ' ' + visitor.lastName

        if (firstAndLast.toLowerCase().includes(s)) hit = true
        else if (lastAndFirst.toLowerCase().includes(s)) hit = true
        else if (visitor.hasOwnProperty('organization')) {
          if (visitor.organization.toLowerCase().includes(s)) hit = true
        }

        return hit
      })
    },
    memberSearchResults: function () {
      return this.members.filter(member => {
        if (this.searchText == null) return false // Avoid null refs...
        let s = this.searchText.toLowerCase()
        let hit = false

        // These could be cached but does it matter?
        let lastAndFirst = member.lastName + ' ' + member.firstName
        let firstAndLast = member.firstName + ' ' + member.lastName

        if (firstAndLast.toLowerCase().includes(s)) hit = true
        else if (lastAndFirst.toLowerCase().includes(s)) hit = true

        return hit
      })
    },
    displayedVisitors: function () {
      return this.visitorSearchResults.slice(0, this.resultLimit)
    },
    displayedMembers: function () {
      return this.memberSearchResults.slice(0, this.resultLimit)
    }
  },
  methods: {
    resetQuickAddForm: function () {
      this.showQuickAddForm = false
      this.newVisitor = {
        firstName: '',
        lastName: '',
        email: '',
        organization: ''
      }
    },
    quickUpdateVisitor: function (updatedVisitor) {
      this.axios.put('/api/visitors/' + updatedVisitor._id, updatedVisitor)
        .then(res => {
          this.$refs.visitorUpdateModal.hide()

          this.$notify({
            group: 'success',
            type: 'success',
            title: 'Visitor updated',
            text: updatedVisitor.firstName + ' ' + updatedVisitor.lastName + ' is now up-to-date!'
          })
        })
        .catch(err => {
          this.$notify({
            group: 'error',
            type: 'error',
            title: 'Updating visitor failed',
            text: err.response.data,
            duration: -1
          })
        })
    },
    quickAddVisitor: function (newVisitor) {
      // Add event subscription
      for (let group of this.groups) {
        if (this.event.organizer.includes(group.name)) {
          newVisitor.interests = {}
          newVisitor.interests[group.id] = true
        }
      }

      this.axios.post('/api/visitors/new', newVisitor)
        .then(res => {
          this.resetQuickAddForm()
          this.visitors.push(res.data)
          this.toggleCheckIn(res.data)
        })
        .catch(err => {
          this.$notify({
            group: 'error',
            type: 'error',
            title: 'Creating visitor failed',
            text: err.response.data,
            duration: -1
          })
        })
    },
    computeAttendance: function () {
      this.totalCheckedInVisitors = 0
      for (let i = 0; i < this.visitors.length; i++) {
        this.visitors[i].checkedIn = false
        for (let j = 0; j < this.event.visitors.length; j++) {
          if (this.visitors[i]._id === this.event.visitors[j].id) {
            this.visitors[i].checkedIn = true
            this.totalCheckedInVisitors++
          }
        }
      }
    },
    loadMore: function () {
      this.resultLimit += 20
    },
    toggleCheckIn (visitor) {
      if (!visitor.isLoading) {
        visitor.isLoading = true
        this.$forceUpdate()

        if (!visitor.checkedIn) {
          // Check-in
          this.axios.post('/api/events/' + this.event._id + '/visitors/add', { id: visitor._id })
            .then(res => {
              if (res.data.result === 'ok') {
                this.lastVisitor = visitor
                this.lastVisitor.oldEmail = visitor.email

                let bestBefore = new Date()
                bestBefore.setFullYear(bestBefore.getFullYear(), bestBefore.getMonth() - 5, 0)
                if (visitor.hasOwnProperty('lastUpdated')) {
                  if (visitor.lastUpdated < bestBefore) this.$refs.visitorUpdateModal.show()
                } else this.$refs.visitorUpdateModal.show()

                this.$notify({
                  group: 'success',
                  type: 'success',
                  title: 'Check-in ok',
                  text: visitor.firstName + ' ' + visitor.lastName + ' is in the house!'
                })
              } else {
                // Was already checked in. No body doubles!
                this.$notify({
                  group: 'error',
                  type: 'error',
                  title: 'Check-in failed',
                  text: visitor.firstName + 'was already checked in. Maybe multiple people editing at the same time?',
                  duration: -1
                })
              }

              this.event = res.data.updatedEvent
              this.computeAttendance()
            })
            .catch(err => {
              this.$notify({
                group: 'error',
                type: 'error',
                title: 'Check-in for ' + visitor.firstName + ' ' + visitor.lastName + ' failed',
                text: err.response.data,
                duration: -1
              })
            })
            .then(() => {
              visitor.isLoading = false
              this.$forceUpdate()
            })
        } else {
          // Check-out
          this.axios.post('/api/events/' + this.event._id + '/visitors/remove', { id: visitor._id })
            .then(res => {
              if (res.data.result === 'ok') {
                this.$notify({
                  group: 'success',
                  type: 'success',
                  title: 'Check-in cancelled',
                  text: visitor.firstName + ' ' + visitor.lastName + ' was never here'
                })
              } else {
                // Was not checked in. Hmm... Reload?
                this.$notify({
                  group: 'error',
                  type: 'error',
                  title: 'Cancelling check-in failed',
                  text: visitor.firstName + ' ' + visitor.lastName + ' was not checked in. Maybe multiple people editing at the same time?',
                  duration: -1
                })
              }

              this.event = res.data.updatedEvent
              this.computeAttendance()
            })
            .catch(err => {
              this.$notify({
                group: 'error',
                type: 'error',
                title: 'Check-in for ' + visitor.firstName + ' ' + visitor.lastName + ' failed',
                text: err.response.data,
                duration: -1
              })
            })
            .then(() => {
              visitor.isLoading = false
              this.$forceUpdate()
            })
        }
      }
    },
    sortByName: function (a, b) {
      if (!a.hasOwnProperty('firstName')) return 1
      if (!b.hasOwnProperty('firstName')) return -1
      let nameA = a.firstName.toLowerCase()
      let nameB = b.firstName.toLowerCase()
      if (nameA < nameB) return -1
      if (nameA > nameB) return 1
      return 0
    }
  },
  mounted: function () {
    this.expiryThreshold = new Date(this.expiryThreshold.setFullYear(this.now.getFullYear(), this.now.getMonth() + 2, 0))

    Promise.all([this.axios.get('/api/visitors'),
      this.axios.get('/api/events/' + this.$route.params.id),
      this.axios.get('/api/visitors/mailchimp/groups'),
      this.axios.get('/api/individual-members/cards/valid'),
      this.axios.get('/api/affiliates/valid')])
      .then(responses => {
        this.groups = responses[2].data

        responses[0].data.sort(this.sortByName)
        this.visitors = responses[0].data

        this.event = responses[1].data

        for (let m of responses[3].data) m.validUntil = new Date(m.validUntil)
        responses[3].data.sort(this.sortByName)
        this.members = responses[3].data

        for (let a of responses[4].data) {
          for (let m of a.members) {
            a.validUntil = new Date(a.validUntil)
            a.createdDate = new Date(a.createdDate)
            this.members.push({
              firstName: m.firstName,
              lastName: m.lastName,
              igdaFinlandId: a.memberIdPrefix + a.createdDate.getFullYear().toString().slice(2) + '-' + m.idSuffix,
              validUntil: a.validUntil,
              membershipType: 'Affiliate'
            })
          }
        }

        let currentDate = new Date().getTime() - 86400000 // 86400000 = 24h
        this.event.date = new Date(this.event.date)

        if (currentDate > this.event.date) this.oldEvent = true

        this.resetQuickAddForm()
        this.computeAttendance()
      })
      .catch(err => {
        this.$notify({
          group: 'error',
          type: 'error',
          title: 'Fetching event, visitor or MailChimp details failed',
          text: err.response.data,
          duration: -1
        })
      })
  }
}
</script>

<style lang="scss">
.checkin-success {
    color:darkgreen;
}
</style>
